<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Incentive Programs"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field v-model="editedItem.name" :rules="validate_rules.name" label="Incentive Program Name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="editedItem.is_deductable"
                        :label="`Can be paid to installment`"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-row justify="center">
            <v-col cols="10">
                <v-toolbar class="search-tools pa-0 mb-4" flat>
                <!-- Action Button -->
                <!-- <v-spacer></v-spacer> -->
                <v-col class="d-flex pl-0" cols="4" sm="4">
                    <v-text-field
                    label="Search ..."
                    v-model="options.filter.keyword"
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    class="hidden-sm-and-down"
                    /> 
                </v-col>
                </v-toolbar>
                <v-data-table 
                    :items="filterList" 
                    :headers="headers"
                    :options.sync="options"
                    sort-by="updatedAt"
                    :sort-desc="true"
                    no-data-text="There is no data"
                >
                <template #item.is_deductable_txt="{item}">
                    {{ item.is_deductable ? "Yes" : "No" }}
                </template>
                <template #item.updatedAt="{value}">
                    {{ value | datetime}}
                </template>
                <template #item.action="{item}">
                  <v-btn 
                    v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                    class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn 
                    v-if="canDo && canDo.can.includes('delete')"
                    class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'IncentiveListings',
  components: {
      pageHeading
  },
  data: () => ({
    dialog: false,
    search: '',
    options: {
        filter: {
          keyword: '',
        }
    },
    editedItem: {
      id: -1,
      name: '',
    },
    defaultItem: {
      id: -1,
      name: '',
    },
    form_valid : false,
    validate_rules : {
      name: [
        v => !!v || 'Name is required',
      ]
    }
  }),
  methods: {
    ...mapActions(['genCan','getIncentives','updateIncentive','createIncentive','deleteIncentive']),
    addItem(){
      this.dialog = true;
    },
    editItem (item) {
      this.editedIndex = this.allIncentives.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this program?') && this.deleteIncentive(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateIncentive(this.editedItem);
        } else {
          this.createIncentive(this.editedItem);
        }
        this.close()
      }
    },
  },
  computed: {
    ...mapGetters(['canDo','allIncentives']),
    headers(){
      var columns = [
        {text: "ID", value: 'id'},
        {text: "Incentive Programs", value: 'name'},
        {text: "Keyname", value: 'slug'},
        {text: "Paid to installment", value: 'is_deductable_txt'},
        {text: "UpdatedAt", value: 'updatedAt'},
        {text: "Action", value: 'action', sortable: false, align: 'center', width: '110px'}, 
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'action');
      }
    },
    filterList() { 
      var filter = this.options.filter;
      return _.filter(this.allIncentives, function(query){
        var keyword = filter.keyword ? query.name.includes(filter.keyword) || query.slug.includes(filter.keyword) : true;
        return  keyword;
      })
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New Incentive' : 'Edit Incentive'
      },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getIncentives();
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>